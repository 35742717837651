<template>
  <div class="home">
    <b-row v-if="true" class="mb-3">
      <b-col cols="12">
         <br/>
        <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 16px 30px; border-radius: 3px 3px 0 0; ">Reports</h4>
        <br/>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/IncomeStatement')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/money-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">INCOME STATEMENTS</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/Performance')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/progress-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">PERFORMANCE</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/Load')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/loads-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">LOADS</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/IncomeStatement')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/fuel-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">FUEL CONSUMPTION</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/Position')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/pin-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">POSITION</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/Repots/FuelConsumption')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/fuel-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">FUEL CONSUMPTION</h6>
        </b-card>
      </b-col>
    </b-row>
     <b-row v-if="true" class="mb-3">
      <b-col cols="12">
         
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 1</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 2</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 3</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 4</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 5</h6>
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="menu-card-outline" @click="navigateTo('/comingsoon')">
        <h1 class="text-center">   <img src="@/assets/maindashboardicons/invoice-icon-01.gif" alt=""  width="50%"></h1>
          <h6 class="mb-0 pb-0 text-center">Report 6</h6>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  data: () => ({
  }),
  created(){
    this.setBreadcrumb([
      {
        text: 'All Reports',
        active: true
      }
    ])
  },
  methods: {
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      navigateTo(route){
        this.$router.push({path: route})
      }
  }
}
</script>
